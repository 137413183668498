@mixin maas-table-actions {
  @media screen and (min-width: $breakpoint-x-small) {
    .p-table-actions {
      display: flex;
      margin-bottom: $spv--large;
    }

    .p-table-actions__space-left,
    .p-table-actions .p-search-box {
      flex: 1 1 auto;
      margin: 0;
    }

    // But default Vanilla adds a space between forms and the top of the first
    // button. Because this form is displayed horizontally the space needs to be
    // to the left of the first button.
    .p-table-actions [class*="p-button"]:first-of-type {
      margin-left: $sph--large;
      margin-top: 0;
    }
  }
}
