@mixin KVMPoolSelect {
  %kvm-pool-select__grid {
    display: grid;
    grid-column-gap: $sph--large;
    grid-template-columns: #{$sph--large * 0.75} 2fr 1fr 3fr;
    word-break: break-word;

    @media only screen and (max-width: $breakpoint-large) {
      grid-template-columns: #{$sph--large * 0.75} 1fr;

      > :nth-child(3),
      > :nth-child(4) {
        display: none;
      }
    }
  }

  .kvm-pool-select {
    display: block;
    .kvm-pool-select__dropdown.p-contextual-menu__dropdown {
      z-index: $side-panel-z-index + 1;
    }

    .kvm-pool-select__header {
      @extend %kvm-pool-select__grid;
      padding-left: $sph--large;
      padding-right: $sph--large;
    }

    .kvm-pool-select__toggle.has-icon {
      @extend %vf-input-elements;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-left: $sph--small;
      width: 100%;

      > :nth-child(1) {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      > :nth-child(2) {
        flex-shrink: 0;
        margin-right: 0;
      }
    }

    .kvm-pool-select__button {
      margin-bottom: 0;
      padding: $spv--small $sph--large;
      text-align: left;
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid $color-mid-light;
      }
    }

    .kvm-pool-select__row {
      @extend %kvm-pool-select__grid;
    }
  }
}
