@mixin maas-double-row {
  $icon-space: map-get($icon-sizes, default);

  .p-double-row--with-icon,
  .p-double-row {
    display: flex;
    flex-direction: row;
  }

  .p-double-row--with-icon .p-double-row__rows-container {
    width: calc(100% - #{$icon-space + $sph--small});
  }

  .p-double-row__rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .p-double-row__icon {
    padding-right: $sph--small;
  }

  .p-double-row__primary-row {
    display: flex;
  }

  .p-double-row__secondary-row {
    @extend %small-text;
    color: $color-mid-dark;
    margin-bottom: 0;
  }

  button.p-double-row__button {
    @extend %small-text;
  }

  .p-double-row__icon-space {
    width: $icon-space;
  }

  .p-double-row__header-spacer {
    padding-left: $icon-space + $sph--small;
  }

  [class*="p-double-row"] .p-table-menu {
    margin-left: math.div($sph--small, 2);
  }

  .p-double-row__tooltip-inner {
    display: inline !important;
    margin-left: $sph--x-small !important;
  }
}
