@mixin RepositoriesList {
  .repo-list td,
  .repo-list th {
    // Name
    &:nth-child(1) {
      flex: 0.3 0 0;
    }

    // URL
    &:nth-child(2) {
      flex: 0.45 0 0;
    }

    // Enabled
    &:nth-child(3) {
      flex: 0.1 0 0;
    }

    // Actions
    &:nth-child(4) {
      flex: 0.15 0 0;
    }
  }

  @media screen and (max-width: 500px) {
    .repo-list td,
    .repo-list th {
      // Name
      &:nth-child(1) {
        flex-grow: 0.65;
      }

      // URL
      &:nth-child(2) {
        display: none;
      }

      // Actions:
      &:nth-child(4) {
        flex-grow: 0.25;
      }
    }
  }

  @media screen and (max-width: 350px) {
    .repo-list td,
    .repo-list th {
      // Enabled
      &:nth-child(3) {
        display: none;
      }

      // Actions:
      &:nth-child(4) {
        flex-grow: 0.35;
      }
    }
  }
}
