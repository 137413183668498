@mixin FabricVLANs {
  .fabric-vlans {
    $vlan-width: 25%;
    $space-width: 25%;
    $subnets-width: 40%;
    $available-width: 10%;

    @media only screen and (min-width: $breakpoint-large) {
      .truncated-border {
        overflow: visible;
        position: relative;

        &::after {
          background-color: $color-light;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          top: -1px;
          width: $vlan-width + $space-width;
        }
      }
    }

    .vlan-col {
      width: $vlan-width;
    }

    .space-col {
      width: $space-width;
    }

    .subnets-col {
      width: $subnets-width;
    }

    .available-col {
      width: $available-width;
    }
  }
}
