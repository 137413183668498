@mixin AppSideNavigation {
  .l-navigation.is-maas {
    @include vf-transition(
      $property: #{width,
      box-shadow,
      background},
      $duration: fast
    );
    &:hover,
    &:focus-within,
    &.is-pinned {
      .l-navigation__controls {
        opacity: 1;
        visibility: visible;
        button {
          background-color: rgba(255, 255, 255, 0.05);
        }
        @media only screen and (min-width: ($breakpoint-small + 1)) {
          transform: translateX(
              #{$application-layout--side-nav-width-expanded - 3rem}
            )
            translateY(0.8rem);
        }
      }
    }
    &.is-collapsed {
      .l-navigation-collapse-toggle,
      .l-navigation__controls {
        i {
          transform: rotate(180deg);
        }
      }
    }
    .l-navigation__controls {
      margin-left: auto;
      padding-top: 0.65rem;
      z-index: $side-navigation-z-index + 1;
      @include vf-transition(
        $property: #{opacity,
        visibility,
        transform,
        background},
        $duration: fast
      );
      @media only screen and (min-width: ($breakpoint-small + 1)) {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0;
        transform: translateX(1rem) translateY(2.6rem);
      }
      @media only screen and (min-width: ($breakpoint-xx-large)) {
        display: none;
      }
    }
    .l-navigation--item-icon {
      margin-right: $sph--small;
    }
  }
  .p-panel.is-dark {
    background: inherit;
    .p-panel__header {
      background-color: inherit;
      @include vf-transition(
        $property: #{width,
        box-shadow,
        background},
        $duration: fast
      );
    }
    .p-panel__logo {
      color: $colors--dark-theme--text-default;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ($breakpoint-small)) {
        margin-top: 1.25rem;
        margin-bottom: 0;
      }
    }
    .p-panel__logo-name {
      @extend %vf-heading-4;
      font-size: #{map-get($font-sizes, h4)}rem;
      line-height: map-get($line-heights, x-small);
      margin-bottom: 1.25rem !important;
      padding-top: 0.051rem !important;
      margin-left: 2rem !important;
      @media only screen and (min-width: ($breakpoint-small + 1)) {
        margin-left: 2.5rem !important;
      }
    }
    .p-panel__content {
      padding-top: 0;
    }
    .p-side-navigation--icons {
      & > .p-side-navigation__list:last-of-type::after {
        content: "";
      }
      .p-side-navigation__list::after {
        left: 1rem;
        opacity: 1;
      }
      .p-side-navigation__footer {
        @extend .p-side-navigation__text;
        padding-left: 1.5rem;
        transition-duration: 0ms;
      }
    }
    .p-navigation__tagged-logo {
      min-width: auto;
    }
    .p-navigation__tagged-logo .p-navigation__logo-tag {
      height: 2.3rem;
      left: 1rem;
      @media only screen and (min-width: ($breakpoint-small + 1)) {
        left: 1.5rem;
      }
    }
    .p-side-navigation--icons button.p-side-navigation__button {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
    }
    .p-side-navigation--icons
      .p-side-navigation__item
      .p-side-navigation__item
      .p-side-navigation__link {
      padding-left: 4rem;
    }
  }
}
