@mixin ControllerListTable {
  .controller-list-table {
    .fqdn-col {
      @include breakpoint-widths(50%, 34%, 35%, 50%, 35%);
    }

    .status-col {
      @include breakpoint-widths(0, 33%, 30%, 4rem, 4rem);
    }

    .type-col {
      @include breakpoint-widths(50%, 33%, 35%, 13.5rem, 13.5rem);
    }

    .vlans-col {
      @include breakpoint-widths(0, 0, 0, 0, 30%);
    }

    .version-col {
      @include breakpoint-widths(0, 0, 0, 50%, 35%);
    }

    .upgrade-col {
      @include breakpoint-widths(0, 0, 0, 10rem, 10rem);
    }

    .images-col {
      @include breakpoint-widths(0, 0, 14rem, 14rem, 14rem);
    }
  }
}
