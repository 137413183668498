@mixin ControllerVLANsTable {
    .controller-vlans-table {
        @media (min-width: $breakpoint-small) {
            th,
            td {
                &:nth-child(1) {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 15%;
                }
                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    width: 20%;
                }
                &:nth-child(5) {
                    width: 20%;
                }
                &:nth-child(6) {
                    width: 20%;
                }
            }
        }
    }
}
