@mixin MachineSelectTable {
  .machine-select-table__row {
    cursor: pointer;

    &:hover {
      background-color: $color-light;
    }

    // The default <strong> font-weight is a bit subtle for highlighting
    // substrings, so it's increased slightly here.
    strong {
      font-weight: 600;
    }
  }
}
