@mixin FormikFormButtons {
  .formik-form-buttons {
    align-items: center;
    display: flex;
    padding: $spv--small 0;

    &.is-bordered {
      border-top: $border;
    }

    &.is-inline {
      display: block;
      padding: 0;
    }

    .formik-form-buttons__button {
      margin-bottom: 0;
    }

    .formik-form-buttons__container {
      flex-shrink: 0;

      // If there is no help text, the buttons should take up the full width.
      &:only-child {
        width: 100%;
      }
    }

    .formik-form-buttons__help {
      flex-grow: 1;
      padding-right: $sph--large;
    }

    @media only screen and (max-width: $breakpoint-small) {
      flex-direction: column;

      .formik-form-buttons__container {
        width: 100%;
      }

      .formik-form-buttons__help {
        padding-right: unset;
        width: 100%;
      }
    }

    @media only screen and (max-width: $breakpoint-large) {
      .formik-form-buttons__container {
        display: flex;
        flex-direction: column;
        gap: $spv--medium;
        padding: $spv--medium 0;

        .formik-form-buttons__button {
          margin-right: 0;
        }
      }
    }
  }
}
