@mixin maas-tables {
  .p-table-expanding--light .p-table__expanding-panel {
    border-top: 1px solid $color-mid-light;
    margin: 0 $sp-small $sp-small $sp-small;
    padding: $sp-small 0 0 0;
  }

  .p-table-expanding--light .p-table__row.is-active {
    background-color: $color-x-light;
  }

  .p-table-sub-cols {
    flex-direction: column;
  }

  .p-table-sub-cols__list {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .p-table-sub-cols__item {
    @extend %single-border-text-vpadding--scaling;
    border-bottom: 1px solid $color-mid-light;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .p-table__header {
    @extend %table-header-label;
    border-bottom: 1px solid $color-mid-light;
    margin-bottom: 0;
    padding-bottom: $spv--medium;
    padding-top: $spv--medium;
    text-transform: uppercase;
  }

  .p-table__row--muted {
    background-color: $color-light;
  }

  .p-table--cell-collapse {
    visibility: collapse;
    display: none;
  }
}
