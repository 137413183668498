@mixin BootArchitecturesTable {
  .boot-architectures-table {
    .name-col {
      width: 25%;
    }

    .bios-boot-col {
      width: 20%;
    }

    .bootloader-col {
      width: 20%;
    }

    .protocol-col {
      width: 15%;
    }

    .octet-col {
      width: 20%;
    }
  }

  .boot-architectures-table__row {
    background-color: $color-x-light;

    &:hover {
      background-color: $color-light;

      &.is-selected {
        background-color: transparentize($color-link, 0.85);
      }
    }
  }
}
