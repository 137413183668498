@mixin maas-typography {
  .p-heading--small {
    @extend %table-header-label;
    color: $color-dark;
    margin-bottom: $sp-unit * 0.25;
    text-transform: uppercase;
  }
  .is-dark {
    .p-heading--small {
      color: $color-light;
    }
  }

  .p-text--paragraph {
    @extend %paragraph;
    font-size: 1rem;
    font-weight: 300;
  }

  .p-text--code {
    font-family: unquote($font-monospace);
  }
}
