@mixin CloneResults {
  .clone-results {
    @extend %base-grid;
    grid-template-areas: "title title title info info info info info info empty empty empty";
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: min-content;

    .clone-results__title {
      grid-area: title;
    }

    .clone-results__info {
      grid-area: info;
    }

    .error-col {
      width: 100%;
    }

    .affected-col {
      width: 9rem;
    }

    @media only screen and (max-width: $breakpoint-x-large) {
      grid-template-areas: "title title title info info info info info info info info info";
    }

    @media only screen and (max-width: $breakpoint-large) {
      grid-template-areas:
        "title"
        "info";
      grid-template-columns: 100%;
    }
  }
}
