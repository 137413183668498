@mixin NodeDevices {
  %node-devices-table {
    $group-label-width: 10rem;
    @include truncated-border();
    .group-col {
      @include breakpoint-widths(0, 0, $group-label-width);
    }

    .vendor-col {
      @include breakpoint-widths(50%, 35%);
    }

    .product-col {
      @include breakpoint-widths(50%, 45%);
    }

    .driver-col {
      @include breakpoint-widths(0, 20%);
    }

    .numa-node-col {
      @include breakpoint-widths(0, 0, 6rem);
    }
  }

  .node-devices-table--pci {
    @extend %node-devices-table;

    .pci-address-col {
      @include breakpoint-widths(0, 8rem);
    }
  }

  .node-devices-table--usb {
    @extend %node-devices-table;

    .bus-address-col {
      @include breakpoint-widths(0, 6rem, 8rem);
    }

    .device-address-col {
      @include breakpoint-widths(0, 6rem, 8rem);
    }
  }
}
